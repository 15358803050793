import { fontCss, getColor, SementicColor } from 'design-system-webview';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Portal from './Portal';
import styled from 'styled-components';

export interface AlertProps {
  visible: boolean;

  title?: React.ReactNode;
  description?: React.ReactNode;
  confirmation?: React.ReactNode;

  hasDim?: boolean;
  hasBodyScrollLock?: boolean;

  hasCancel?: boolean;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  okColor?: SementicColor | string;
  cancelColor?: SementicColor | string;
  confirmColor?: SementicColor | string;
  onOk?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;

  portalId?: string;
  dimAttrs?: React.HTMLAttributes<HTMLDivElement>;
  containerAttrs?: React.HTMLAttributes<HTMLDivElement>;

  zIndex?: number;
}

export function Alert({
  visible = false,

  title,
  description,
  confirmation,

  hasDim = true,
  hasBodyScrollLock = true,

  hasCancel = true,
  okText = '확인',
  cancelText = '취소',

  okColor = 'Text/Accent',
  cancelColor = 'Text/Primary',
  confirmColor = 'Text/Primary',
  onOk,
  onCancel,

  portalId = 'portal',
  dimAttrs,
  containerAttrs,

  zIndex = 1,
}: AlertProps) {
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (!hasBodyScrollLock) return;
    if (!visible) return;

    document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;

      overflow-y: scroll;
      width: 100%;
    `;

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [hasBodyScrollLock, visible]);

  // useEffect(() => {
  //   if (visible) mobileAppFuction('deactivateSwipe');
  //   if (!visible) mobileAppFuction('activateSwipe');
  // }, [visible]);

  if (!visible) return null;

  return (
    <Portal portalId={portalId}>
      {hasDim && <Dim $zIndex={zIndex as number} />}
      <Container $zIndex={zIndex as number}>
        <Content>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
          {confirmation && (
            <ConfirmLine onClick={() => setConfirmed((prev) => !prev)}>
              <CheckCircle $confirmed={confirmed} $confirmColor={confirmColor}>
                {CheckSvg}
              </CheckCircle>
              <ConfirmText>{confirmation}</ConfirmText>
            </ConfirmLine>
          )}
        </Content>
        <Buttons>
          {hasCancel && (
            <Button
              style={{ color: getColor(cancelColor) }}
              onClick={onCancel}
              whileTap={{ filter: 'brightness(0.93)' }}
            >
              {cancelText}
            </Button>
          )}
          <Button
            whileTap={{ filter: 'brightness(0.93)' }}
            disabled={!!(confirmation && !confirmed)}
            style={{ color: confirmation && !confirmed ? getColor('Text/Inactive') : getColor(okColor) }}
            onClick={onOk}
          >
            {okText}
          </Button>
        </Buttons>
      </Container>
    </Portal>
  );
}

const Dim = styled.div<{ $zIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transform: translate3d(0, 0, 0); /* safari zindex bug fix */
  z-index: ${({ $zIndex }) => $zIndex};
`;

const Container = styled.div<{ $zIndex: number }>`
  width: 100%;
  max-width: 312px;
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) translate3d(0, 0, 0) /* safari zindex bug fix */;

  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  z-index: ${({ $zIndex }) => $zIndex};
`;

const Content = styled.div`
  padding: 28px 28px 36px 28px;
`;

const Description = styled.div`
  ${fontCss('Body/B1_R')}
  color: ${getColor('Text/Primary')};
  white-space: pre-line;
`;

const ConfirmLine = styled.div`
  white-space: pre-line;
  margin-top: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.p`
  ${fontCss('Heading/HD5_B')}
  color: ${getColor('Text/Primary')};
  white-space: pre-wrap;

  /* Title이 존재할 때에만 Description에 마진 */
  & + ${Description} {
    margin-top: 12px;
  }
`;

const CheckCircle = styled.div<{ $confirmed: boolean; $confirmColor: string }>`
  margin-right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  border: ${({ $confirmed }) => ($confirmed ? 'none' : '1.5px solid #cccccc')};
  background-color: ${({ $confirmed, $confirmColor }) => ($confirmed ? $confirmColor : 'none')};
`;

const ConfirmText = styled.p`
  ${fontCss('Body/B2_R')}
  color: ${getColor('Text/Primary')};
`;

const Buttons = styled.div`
  display: flex;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  & *:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Button = styled(motion.button)`
  ${fontCss('Label/Label2_SB')}

  flex: 1;
  padding: 20px 0;
  border: none;
  outline: none;
  background-color: #ffffff;

  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
`;

const CheckSvg = (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.14307 7.143L5.7145 11.7144L14.8574 1.42871"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
